import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { flatMap, map } from "rxjs/operators";

import { IUser } from "../model/IUser";
import { Token } from "../model/Token";
import { UserStorageService } from "./user-storage.service";
import { UserService } from "./user.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<IUser>;
  public currentUser: Observable<IUser>;

  constructor(
    private http: HttpClient,
    private userStore: UserStorageService,
    private userService: UserService
  ) {
    this.currentUserSubject = new BehaviorSubject<IUser>(userStore.getUser());
    this.currentUser = this.currentUserSubject.asObservable();
  }

  get currentUserValue(): IUser {
    return this.currentUserSubject.value;
  }

  /**
   *
   */
  login(username: string, password: string): Observable<IUser> {
    const headers = new HttpHeaders({
      accept: "*/*",
      "content-type": "application/x-www-form-urlencoded; charset=utf-8",
    });
    const body =
      `client_id=platform-ui&client_secret=secret&username=` +
      username +
      `&password=` +
      password +
      `&grant_type=password`;
  
    const options = {
      headers,
    };
    console.log("calling")
    return this.http.post<any>(`/oauth2-ms/oauth/token?`, body, options).pipe(
      flatMap((token: Token) => {
        // login successful if there's a jwt accessToken in the response
        if (token.access_token) {
          token.exp_time = token.expires_in
            ? (token.expires_in - 10) * 1000 + new Date().getTime()
            : 0;
          const options2 = {
            headers: new HttpHeaders({
              Authorization: `Bearer ${token.access_token}`,
            }),
          };
  
          return this.http.get<any>(`/oauth2-ms/api/v1/users/me`, options2).pipe(
            flatMap((user: IUser) => {
              if (user) {
                user.accessToken = token;
                // store user details and jwt accessToken in local storage to keep user logged in between page refreshes
                this.userStore.saveUser(user);
                
                this.currentUserSubject.next(user);
  
                // Call another API after fetching the user details
                const options3 = {
                  headers: new HttpHeaders({
                    Authorization: `Bearer ${token.access_token}`,
                  }),
                };
                console.log("Second api call done")

                return this.http
                  .get<any>(`/healthcare-facility-ms/api/v1/accounts-by-email?email=${user.login}`)
                  .pipe(
                    map((additionalData: any) => {
                      // Do something with additionalData if needed
                      user.passwordChangeRequired = additionalData?.passwordChangeRequired;
                      this.userStore.saveUser(user);
                      //user.additionalData = additionalData;
                      return user;
                    })
                  );
              }
  
              throw new Error("Failed to fetch user details");
            })
          );
        }
  
        throw new Error("Failed to obtain access token");
      })
    );
  }
  

  refreshToken(): Observable<any> {
    const headers = new HttpHeaders({
      accept: "*/*",
      "content-type": "application/x-www-form-urlencoded; charset=utf-8",
    });
    var user = JSON.parse(localStorage.getItem("auth-user"));
    const body =
      `client_id=platform-ui&client_secret=secret&grant_type=refresh_token&refresh_token=` +
      user["accessToken"]["refresh_token"];

    const options = {
      headers,
    };

    return this.http
      .post<any>(`/oauth2-ms/oauth/token?`, body, options)
      .pipe(
        map((token: Token) => {
          if (token.access_token) {
            token.exp_time = token.expires_in
              ? (token.expires_in - 10) * 1000 + new Date().getTime()
              : 0;
            this.currentUserValue.accessToken = token;
            // store user details and jwt accessToken in local storage to keep user logged in between page refreshes
            this.userStore.saveUser(this.currentUserValue);
            this.userStore.saveToken(this.currentUserValue.accessToken);
            this.currentUserSubject.next(this.currentUserValue);
          }
        })
      );
  }

  getProvisionalToken() {
    const headers = new HttpHeaders({
      accept: "*/*",
      "content-type": "application/x-www-form-urlencoded",
    });
    const body = `client_id=platform-ui&client_secret=secret&grant_type=client_credentials`;
    const options = {
      headers: headers,
    };
    return this.http.post<any>(`/oauth2-ms/oauth/token`, body, options);
  }

  logout() {
    // remove user from local storage to log user out
    this.userStore.signOut();
    this.currentUserSubject.next(null);
    this.userService.logout(null);
  }

  isLoggedIn() {
    return (
      this.currentUserValue &&
      this.currentUserValue.accessToken &&
      this.currentUserValue.accessToken.access_token
    );
  }

  isExpired() {
    return (
      this.isLoggedIn() &&
      this.currentUserValue.accessToken.exp_time < new Date().getTime()
    );
  }
  
}
