import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AdminService } from '../ms/admin/service/admin.service';
import { UserService } from '../ms/oauth2/service/user.service';
import { UserStorageService } from '../ms/oauth2/service/user-storage.service';
import { UserChangeService } from '../ms/oauth2/service/user-change.service';

@Injectable()
export class PasswordChangeInterceptor implements HttpInterceptor {
  constructor(private router: Router, private userStorageService: UserStorageService, private userChangeService: UserChangeService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          let passwordChangeRequired = this.userStorageService.getUser()?.passwordChangeRequired;
          if(passwordChangeRequired) this.router.navigate(["/change-password"]);
        }
      })
    );
  }
}

export const PasswordChangeInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: PasswordChangeInterceptor,
  multi: true,
};
