import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Admin } from '../model/Admin';


@Injectable({
  providedIn: 'root',
})
export class AdminService {
  baseUrl: string = '/healthcare-facility-ms/api/v1/admins';

  constructor(private http: HttpClient) { }

    addNewAdmin(admin:Admin){

      return this.http.post(this.baseUrl, admin)
    }

    getAdmin(){
      return this.http.get(this.baseUrl)
    }

    private delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async getAdminEveryMinute(){
      await this.http.get(this.baseUrl).toPromise().then(response => {
        console.log('Admin data:', response);
      }).catch(error => {
        console.error('Error fetching admin data:', error);
      });
      await this.delay(60000);

     

    }
    getAllAdmins(): Observable<any> {
      return this.http.get<any>(this.baseUrl + "/get-all");
    }
}
