import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription, BehaviorSubject, interval } from 'rxjs';
import { UserStorageService } from './user-storage.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserChangeService implements OnInit, OnDestroy {
    private subscription!: Subscription;
    private apiUrl = '/healthcare-facility-ms/api/v1/accounts/'; 

    constructor(private http: HttpClient, private userStorageService: UserStorageService) { }


    startPeriodicApiCall() {
        // Call your API every 60 seconds (60000 ms)
        interval(60000)
            .pipe(
                switchMap(() => this.getAccountById()) 
            )
            .subscribe({
                next: (response) => {
                    let user = this.userStorageService.getUser();
                    if (user.passwordChangeRequired !== response["passwordChangeRequired"]) {
                        user.passwordChangeRequired = response["passwordChangeRequired"]
                        this.userStorageService.saveUser(user);
                    }
                },
                error: (err) => {
                    console.error('API Error:', err);
                },
            });
    }

    ngOnInit(): void {

    }

    getAccountById() {
        // Replace with your actual API endpoint
        let userId = this.userStorageService.getUser()?.sub;
        if (userId !== undefined) return this.http.get(this.apiUrl + userId);
    }

    ngOnDestroy(): void {
        // Unsubscribe to prevent memory leaks
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


}
