import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UserStorageService } from "./user-storage.service";
import { IUserData, ANONYMOUS_USER } from "../model/IUser";

@Injectable({ providedIn: "root" })
export class UserService {
  protected authenticatedUserData: BehaviorSubject<
    IUserData
  > = new BehaviorSubject<IUserData>(ANONYMOUS_USER);

  constructor(
    private http: HttpClient,
    private userStore: UserStorageService
  ) { }

  get currentUserData(): Observable<IUserData> {
    return this.authenticatedUserData;
  }


  resetPasswordWithVerificationCode(verificationCode: string, accessToken: any){
    const headers = new HttpHeaders({
      "Authorization": `Bearer ${accessToken.access_token}`,
    });
    const options = {
      headers: headers,
    };
    return this.http.get("/healthcare-facility-ms/api/v1/ResetPasswordRequest/VerificationAndReset?verificationCode=" + verificationCode ,options);

  }


  resetPasswordWithToken(token: string, password: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      "Authorization": `Bearer ${accessToken.access_token}`,
    });
    const options = {
      headers: headers,
    };
    return this.http.put("/healthcare-facility-ms/api/v1/account/resetPasswordByverificationToken?token="+ token +"&password=" + password,{} ,options);
  } 
  confirmRegistration(mailToken: string, password: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      "Authorization": `Bearer ${accessToken.access_token}`,
    });
    const options = {
      headers: headers,
    };
    return this.http.put("/healthcare-facility-ms/api/v1/verification-tokens/email-confirmation?verification_token=" + mailToken,{} ,options);
  }
  resetPassword(email: string, password: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      "Authorization": `Bearer ${accessToken.access_token}`,
    });
    const options = {
      headers: headers,
    };
    return this.http.put("/healthcare-facility-ms/api/v1/account/forgetPassword?email=" + email,{} ,options);
  }

  setPasswordChangeRequired(id: number, passwordChangeRequired: boolean, accessToken: any): Observable<any>{
    const headers = new HttpHeaders({
      "Authorization": `Bearer ${accessToken.access_token}`,
    });
    const options = {
      headers: headers,
    };
    return this.http.patch("/healthcare-facility-ms/api/v1/account/"+ id +"/password-change-required?isPasswordChangeRequired=" + passwordChangeRequired,{} ,options);
  }

  changePassword(id: number, password: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      "Authorization": `Bearer ${accessToken.access_token}`,
    });
    const options = {
      headers: headers,
    };
    return this.http.put("/healthcare-facility-ms/api/v1/account/resetPassword?idAccount=" + id+"&password=" + password,{} ,options);
  }
  acceptInvitation(mailToken: string, password: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      "Authorization": `Bearer ${accessToken.access_token}`,
    });
    const options = {
      headers: headers,
    };
    return this.http.put("/healthcare-ms/api/v1/invitations/" + mailToken + "/accept",{} ,options);
  }

  logout(param) {
    this.authenticatedUserData.next(ANONYMOUS_USER);
  }
}
